import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import { CurItemProvider } from "./Cursos/CursContext";
import { ModeProvider } from "./Context/ContextMode";
import { HashRouter, Route, Routes } from "react-router-dom";
import { RoutePageHome } from "./Pages/RoutePage";
import { RoutePageCur } from "./Cursos/RoutePageCur";
import { RoutePageRecur } from "./Pages/RoutePageRecur";
import { Error404 } from "./Pages/Error404";
import { RoutePageMoreEmma } from "./Pages/RoutePageMoreEmma";
import {CompraCur} from "./Cursos/CompraCur";
import {DetailCurs} from "./Cursos/DetailCurs";
import ScrollToTop from "./Pages/ScrollToTop";
import { CursoDemo } from "./Cursos/CursoDemo";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <CurItemProvider>
      <ModeProvider>
        <HashRouter>
        <ScrollToTop/>
          <Routes>
            <Route path="/" element={<RoutePageHome />} />
            <Route path="/Cursos" element={<RoutePageCur />} />
            <Route path="/MoreEmma" element={<RoutePageMoreEmma />} />
            <Route path="/Recursos" element={<RoutePageRecur />} />
            <Route path="/Compra/:idcurso" element ={<CompraCur/>}/>
            <Route path="/Curso/Detalle/:idcurso" element ={<DetailCurs/>}/>
            <Route path="/Curso/Detalle/VidDemo/:idcurso" element ={<CursoDemo/>}/>
            <Route path="*" element={<Error404 />} />
          </Routes>
        </HashRouter>
      </ModeProvider>
    </CurItemProvider>
  </>
);
