import React, { Component, useContext } from "react";
import "./VideoFondo.css";
import video from "./Vid/IoT.mp4";
import ReactPlayer from "react-player";
import Row from "react-bootstrap/esm/Row";

import { ModeContex } from "../Context/ContextMode";

export function VideoFondo() {
  const { themeModeP, themeModeS } = useContext(ModeContex);

  return (
    <>
        <Row id='home' className={`${" align-items-center justify-content-center "} ${themeModeS}`}>
       
          <div className="contvideo">
            <ReactPlayer className="video" url={video} playing muted autoPlay loop />
          </div>
          <div className={` ${"cardvideo align-items-center"} ${themeModeS}`}>
            <div id="vidCard" className="text-center ">
              <div id="LogoSlogan" className="pt-2 ">Emma-IoT</div>
              <div id="Slogan" className="pt-1"> Controla el internet, Mejora tu vida.</div>
            </div>
          </div>
        </Row>
        
    </>
  );
}
