import React, { useContext } from "react";
import "./Footer.css";
import { ModeContex } from "../Context/ContextMode";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
function Footer() {
  const { themeModeP } = useContext(ModeContex);
  return (
    <>
      <Row className={`${themeModeP} ${"  text-center"}`}>
        <Col className="footer">
        COPYRIGHT © 2023. DESIGNED BY EMMA-IOT COMPANY
        </Col>
      
      </Row>
    </>
  );
}

export default Footer;
