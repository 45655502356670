import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import iconModeD from "./img/Darkmode.png";
import iconModeL from "./img/Lightmode.png";
import React, { useContext } from "react";
import Row from "react-bootstrap/esm/Row";
import { Link as LinkScroll } from "react-scroll/modules";
import { Link as LinkRouter } from "react-router-dom";
import { ModeContex } from "../Context/ContextMode";

import "@theme-toggles/react/css/InnerMoon.css"
export function NavBar() {
  const { selectModetheme, theme,NavBarMode,emmalogo } = useContext(ModeContex);

  return (
    <>
      <Container className={`${"fixed-top"} ${NavBarMode}`} fluid>
        <Row>
          <Navbar collapseOnSelect expand="lg" variant={theme}>
            <Container>
              <LinkScroll
                className={`${"emmalogo navbar-brand"} ${emmalogo}`}
                
                to="home"
                activeclassname="active"
            
                offset={0}
                duration={500}
              >
                Emma-IoT
              </LinkScroll>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                  <LinkScroll
                    to="Sec1"
                    className="nav-link"
                    activeclassname="active"
                 
                    offset={0}
                    duration={500}
                  >
                    Nosotros
                  </LinkScroll>
                  <LinkScroll
                    to="Cursos"
                    className="nav-link"
                    activeclassname="active"
              
                    offset={0}
                    duration={500}
                  >
                    Cursos
                  </LinkScroll>
                  <LinkScroll
                    to="Contactanos"
                    className="nav-link"
                    activeclassname="active"
                    
                    offset={0}
                    duration={500}
                  >
                    Contactanos
                  </LinkScroll>
                  <LinkRouter
                    to="/Recursos"
                    className="nav-link"
                    activeclassname="active"
                   
                    offset={0}
                    duration={500}
                  >
                    Recursos
                  </LinkRouter>
                  {selectModetheme.estado ? <DarkMode /> : <LightMode />}

                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </Row>
      </Container>
    </>
  );
}



export function NavbarComprar(){
  const { selectModetheme, theme, emmalogo,NavBarMode } = useContext(ModeContex);

  return(
    <>

<Container className={`${"fixed-top"} ${NavBarMode}`} fluid>
        <Row>
          <Navbar collapseOnSelect expand="lg" variant={theme}>
            <Container>
              <LinkRouter
                className={`${"emmalogo navbar-brand"} ${emmalogo}`}
                
                to="/"
                activeclassname="active"
             
                offset={0}
                duration={500}
              >
                Emma-IoT
              </LinkRouter>
                <Nav className="justify-content-end">
                  
                  <LinkRouter
                    to="/"
                    className="nav-link"
                    activeclassname="active"
                  
                    offset={0}
                    duration={500}
                  >
                    Cancelar
                  </LinkRouter>
                </Nav>
            </Container>
          </Navbar>
        </Row>
      </Container>
    </>
  );
}


export function NavbarSeccion(){
  const { selectModetheme, theme, emmalogo,NavBarMode } = useContext(ModeContex);

  return(
    <>

<Container className={`${"fixed-top"} ${NavBarMode}`} fluid>
        <Row>
        <Navbar collapseOnSelect expand="lg" variant={theme}>
            <Container>
              <LinkRouter
                className={`${"emmalogo navbar-brand"} ${emmalogo}`}
                
                to="/"
                activeclassname="active"
                
                offset={0}
                duration={500}
              >
                Emma-IoT
              </LinkRouter>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                
                  <LinkRouter
                    to="/"
                    className="nav-link"
                    activeclassname="active"
                   
                    offset={0}
                    duration={500}
                  >
                    Inicio
                  </LinkRouter>
                  <LinkRouter
                    to="/Cursos"
                    className="nav-link"
                    activeclassname="active"
                    offset={0}
                    duration={500}
                  >
                    Cursos
                  </LinkRouter>
                  <LinkRouter
                    to="/MoreEmma"
                    className="nav-link"
                    activeclassname="active"
                  
                    offset={0}
                    duration={500}
                  >
                    Quienes somos?
                  </LinkRouter>
                  <LinkRouter
                    to="/Recursos"
                    className="nav-link"
                    activeclassname="active"
                  
                    offset={0}
                    duration={500}
                  >
                    Recursos
                  </LinkRouter>
                  {selectModetheme.estado ? <DarkMode /> : <LightMode />}
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </Row>
      </Container>
    </>
  );
}

function DarkMode() {
  const { darkMode } = useContext(ModeContex);
  return (
    <>
      <Nav.Link onClick={darkMode}>
        Dark Mode
        <img className=" iconMode" src={iconModeD} />
      </Nav.Link>
    </>
  );
}

function LightMode() {
  const { lightMode } = useContext(ModeContex);

  return (
    <>
      <Nav.Link onClick={lightMode}>
        Light Mode
        <img className=" iconMode" src={iconModeL} />
      </Nav.Link>
    </>
  );
}