import React from "react";
import { NavBar } from "../navBar/NavBar";
import { VideoFondo } from "../VideoFondo/VideoFondo";
import { Redes } from "../redes/redes";
import { Nosotros } from "../Nosotros/Nosotros";
import { Cursos } from "../Cursos/Cursos";
import { Contacto } from "../Contacto/Contacto";
import Footer from "../Footer/Footer";
import Container from "react-bootstrap/esm/Container";

export function RoutePageHome() {
  return (
    <>
      <NavBar />
      <Container fluid>
        <VideoFondo />
        <Nosotros />
        <Cursos />
        <Contacto />
        <Redes />
        <Footer />
      </Container>
    </>
  );
}
