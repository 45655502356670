import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import React, {  useContext } from "react";
import { ModeContex } from "../Context/ContextMode";
import { ImageCarrusel } from "./Carrusel";
import { Link as LinkRouter } from "react-router-dom";
import "./nosotros.css"

export function Nosotros() {
    const { themeModeS,themeModeButton } = useContext(ModeContex);

  return (
    <>
      <Row
        id="Sec1"
        className={`${" seccion align-items-center justify-content-center "} ${themeModeS}`}
      >
<Col xs={12} lg={5} className="text-center">
<h1 className="emmaTitulo">¿Que es Emma-IoT?</h1>
          <p className="justify">
          Emma es un ecosistema de software y hardware creado para satisfacer necesidades  cotidianas por medio de internet de las cosas.
          </p>
          <p className="justify">

La tarjeta <i className="emmaText">Emma</i> realiza las conexiones físicas hacia los dispositivos tanto de control como de monitoreo, que mediante Emma-App se puedan visualizar y procesar los datos para emitir órdenes a los actuadores físicos.

          </p>


         
         
          <div className="btn-item">
          <LinkRouter  to="/MoreEmma"  className={`${"blue"} ${themeModeButton}`}>
          Conoce mas sobre Emma-Iot
          </LinkRouter>
          </div>

</Col>

<Col xs={12} lg={6} >
<ImageCarrusel/>

</Col>





      </Row>
    </>
  );
}
