import React, { children, createContext, useState } from "react";

const ModeContex = createContext();
const ModeProvider = ({ children }) => {


    //Estado del IconMode
  const [selectModetheme, setSelectModetheme] = useState({
    estado: false,
  });

  //Estado del ThemeMode
  const [themeModeP, setThemeModeP]= useState('darkModeP')
  const [NavBarMode, setNavBarMode]= useState('navBarDark')
  const [themeModeS, setThemeModeS]= useState('darkModeS')
  const [emmalogo, setEmmalogo]= useState('emmalogoL')
  const [detailsC, setDetailsC]= useState('detailsD')
  const [themeModeButton, setthemeModeButton]= useState('btn-ghost blue round')

  const [theme, setTheme]= useState('dark')

  //Funcion para activar el modo Dark

  const darkMode = () => {
    setSelectModetheme((previousState) => {
      return { ...previousState, estado: false };
    });
    setThemeModeP('darkModeP');
    setNavBarMode('navBarDark');
    setThemeModeS('darkModeS');
    setTheme('dark');
    setEmmalogo('emmalogoD')
    setthemeModeButton('btn-ghost blue round');
    setDetailsC('detailsD');


  };

  
  //Funcion para activar el modo Light

  const lightMode = () => {
    setSelectModetheme((previousState) => {
      return { ...previousState, estado: true };
    });
    setNavBarMode('navBarLight');
    setThemeModeP('lightModeP');
    setEmmalogo('emmalogoL')
    setThemeModeS('lightModeS');
    setTheme('light')
    setthemeModeButton('btn-ghost  secundary round');
    setDetailsC('detailsL');



  };

  const data = {
    themeModeP,
    themeModeS,
    theme,
    emmalogo,
    selectModetheme,
    themeModeButton,
    NavBarMode,
    detailsC,
    darkMode,
    lightMode,
    
  };

  return <ModeContex.Provider value={data}>{children}</ModeContex.Provider>;
};
export { ModeProvider };

export { ModeContex };
