import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ReactPlayer from "react-player";
import { NavbarSeccion } from "../navBar/NavBar";
import { useParams } from "react-router-dom";
import { CursContex } from "./CursContext";
import { ModeContex } from "../Context/ContextMode";
import Footer from "../Footer/Footer";
import { Link as LinkRouter } from "react-router-dom";
import imgReturL from "./img/ReturL.svg";
import imgReturD from "./img/ReturnD.svg";

export function CursoDemo() {
  let { idcurso } = useParams();
  let { card } = useContext(CursContex);
  let CurDet = card.find((card) => card.idCurso === idcurso);
  const { themeModeS, selectModetheme, themeModeButton, themeModeP } =
    useContext(ModeContex);
  return (
    <>
      <NavbarSeccion />
      <Container fluid>
        <Row className={`${" seccion justify-content-center"} ${themeModeS}`}>
          <div className="contvideo">
            <ReactPlayer
              url={CurDet.vidDemo}
              className="video"
              playing
              controls
            />
          </div>
        </Row>
        <Row className={`${"  justify-content-center"} ${themeModeS}`}>
          <Col xs={4} lg={2} className="text-center">
            <LinkRouter
              to={`/Curso/Detalle/${idcurso}`}
              className={`${"blue"} ${themeModeButton}`}
            >
              {selectModetheme.estado ? (
                <img className="iconcur" src={imgReturL} />
              ) : (
                <img className="iconcur" src={imgReturD} />
              )}
              Regresar
            </LinkRouter>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Footer />
      </Container>
    </>
  );
}
