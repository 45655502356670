import React, { useContext } from "react";
import { CursContex } from "./CursContext";
import { CardCurso, EjemCurso } from "./Curso";
import "./cardcursos.css";
import Row from "react-bootstrap/esm/Row";
import { ModeContex } from "../Context/ContextMode";
import Col from "react-bootstrap/esm/Col";
import { Link as LinkRouter } from "react-router-dom";

export function Cursos() {
  const { themeModeS, themeModeButton } = useContext(ModeContex);
  const { CurOnline, CurOffline, CurPresen } = useContext(CursContex);
  return (
    <>
      <Row
        id="Cursos"
        className={`${"seccion align-items-center justify-content-center "} ${themeModeS}`}
      >
        <Col xs={12} className="text-center">
          <h1 className="emmaTitulo">CURSOS MÁS POPULARES</h1>
        </Col>
      </Row>
      <Row className={`${" justify-content-center "} ${themeModeS}`}>
        {
          <CardCurso
            key={CurOnline[0].idCurso}
            idCur={CurOnline[0].idCurso}
            tittle={CurOnline[0].tittleCurso}
            imageSource={CurOnline[0].imageCurso}
            descripCur={CurOnline[0].descripCurso}
            modCur={CurOnline[0].modalidadCurso}
            niv={CurOnline[0].nivel}
            precio={CurOnline[0].precioCurso}
            iniCur={CurOnline[0].fechacur}
            totHor={CurOnline[0].totHor}
          />
        }
        {
          <CardCurso
            key={CurOffline[0].idCurso}
            idCur={CurOffline[0].idCurso}
            tittle={CurOffline[0].tittleCurso}
            imageSource={CurOffline[0].imageCurso}
            descripCur={CurOffline[0].descripCurso}
            modCur={CurOffline[0].modalidadCurso}
            niv={CurOffline[0].nivel}
            precio={CurOffline[0].precioCurso}
            iniCur={CurOffline[0].fechacur}
            totHor={CurOffline[0].totHor}
          />
        }
        {
          <CardCurso
            key={CurPresen[0].idCurso}
            idCur={CurPresen[0].idCurso}
            tittle={CurPresen[0].tittleCurso}
            imageSource={CurPresen[0].imageCurso}
            descripCur={CurPresen[0].descripCurso}
            modCur={CurPresen[0].modalidadCurso}
            niv={CurPresen[0].nivel}
            precio={CurPresen[0].precioCurso}
            iniCur={CurPresen[0].fechacur}
            totHor={CurPresen[0].totHor}
          />
        }
      </Row>
      <Row className={`${" justify-content-center "} ${themeModeS}`}>
        <Col xs={11} sm={8} lg={6} className="text-center">
          <LinkRouter to="/Cursos" className={`${"blue"} ${themeModeButton}`}>
            Ver todos nuestros Cursos
          </LinkRouter>
        </Col>
      </Row>
    </>
  );
}
