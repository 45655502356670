import React, { useContext } from "react";
import img1 from "./img/1.png";
import img2 from "./img/2.png";
import img3 from "./img/3.jpg";
import slideLeft from "./img/slide-left.png";
import slideRight from "./img/slide-right.png";

export function ImageCarrusel() {

  return (
    <>
      <div id="carouselExampleIndicators" className="carousel slide ">
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>

        <div className="carousel-inner text-center">
          <div className="carousel-item active ">
            <img src={img1} className=" carruselimage " alt="..." />
          </div>
          <div className="carousel-item">
            <img src={img2} className=" carruselimage" alt="..." />
          </div>
          <div className="carousel-item">
            <img src={img3} className=" carruselimage" alt="..." />
          </div>
        </div>


        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="prev"
        >
          <span  aria-hidden="true">
              <img src={slideLeft} className=" carousel-control-icon"/>
            </span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="next"
        >

          <span  aria-hidden="true">
              <img src={slideRight} className=" carousel-control-icon"/>
            </span>
        </button>
      </div>
    </>
  );
}
