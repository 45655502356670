import { useContext } from "react";
import { ModeContex } from "../Context/ContextMode";
import { Link as LinkRouter } from "react-router-dom";

import "./cardcursos.css";
import imgmuestr from "./img/Curpend.jpg";
import imgTimeL from "./img/TimeL.svg";
import imgTimeD from "./img/TimeD.svg";
import imgCalendL from "./img/calendL.svg";
import imgCalendD from "./img/calendD.svg";
import Col from "react-bootstrap/esm/Col";
import { Row } from "react-bootstrap";

export function CardCurso({
  tittle,
  idCur,
  imageSource,
  descripCur,
  modCur,
  iniCur,
  niv,
  totHor,
}) {
  const { selectModetheme, themeModeP, themeModeButton, detailsC } =
    useContext(ModeContex);

  return (
    <>
      <Col border="secondary" xs={12} sm={10} md={8} lg={6} xl={4} className="mb-2">
        <div className={`${"card text-center"} ${themeModeP}`}>
          <div className="overflow">
            <div className={`${"modcurso text-center"} ${themeModeP}`}>
              <h5 className="card-title ">{tittle}</h5>
            </div>
            <img className="card-img-top imgcurso" src={imageSource} />
          </div>
          <div className={`${"card-body"} {themeModeP}`}>
            <Row className="">
              <Col xs={12} className="justify">{descripCur}</Col>
            </Row>
            <Row className="text-center pt-2 bold text-center">
              <Col xs={5} md={5} xl={4}>
                {selectModetheme.estado ? (
                  <img className="iconcur" src={imgCalendL} />
                ) : (
                  <img className="iconcur" src={imgCalendD} />
                )}
                {iniCur}
              </Col>

              <Col xs={"auto"} xl={4} className="text-center">
                {niv}
                </Col>

              <Col xs={4} sm={3} xl={4} >
                {selectModetheme.estado ? (
                  <img className="iconcur" src={imgTimeD} />
                ) : (
                  <img className="iconcur" src={imgTimeL} />
                )}

                {totHor}
              </Col>
            </Row>

            <div className="btn-item">
              <LinkRouter
                to={`/Compra/${idCur}`}
                className={`${"blue"} ${themeModeButton}`}
              >
                Comprar
              </LinkRouter>
              <LinkRouter
                to={`/Curso/Detalle/${idCur}`}
                className={`${"blue"} ${themeModeButton}`}
              >
                Más información
              </LinkRouter>
            </div>
          </div>
        </div>
      </Col>
    </>
  );
}

export function EjemCurso() {
  return (
    <>
      <div className="col-md-9 col-lg-4 ">
        <div className="card" aria-hidden="true">
          <div className="overflow">
            <img className="card-img-top" src={imgmuestr} />
          </div>
          <div className="card-body">
            <h5 className="card-title placeholder-glow">
              <span className="placeholder col-6"></span>
            </h5>
            <p className="card-text placeholder-glow">
              <span className="placeholder col-7"></span>
              <span className="placeholder col-4"></span>
              <span className="placeholder col-4"></span>
              <span className="placeholder col-6"></span>
              <span className="placeholder col-8"></span>
            </p>
            <a
              href="#"
              tabIndex="-1"
              className="btn btn-secondary disabled placeholder col-3"
            ></a>
          </div>
        </div>
      </div>
    </>
  );
}
