import React, { useContext } from "react";
import { CursContex } from "../Cursos/CursContext";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { NavbarSeccion } from "../navBar/NavBar";
import { ModeContex } from "../Context/ContextMode";
import imgTimeL from "./img/TimeL.svg";
import imgTimeD from "./img/TimeD.svg";
import imgCalendL from "./img/calendL.svg";
import imgCalendD from "./img/calendD.svg";
import imgPlayD from "./img/PlayD.svg";
import imgPlayL from "./img/PlayL.svg";
import imgCheck1 from "./img/cheque.png";
import { Link as LinkRouter } from "react-router-dom";

import "./cardcursos.css";
import Footer from "../Footer/Footer";
import { InfoContact } from "../Contacto/Contacto";



export function DetailCurs() {
  let { idcurso } = useParams();
  let { card } = useContext(CursContex);
  let CurDet = card.find((card) => card.idCurso === idcurso);
  const { themeModeS, selectModetheme, themeModeButton, themeModeP } =
    useContext(ModeContex);

  return (
    <>
      <NavbarSeccion />
      <Container fluid>
        <Row
          className={`${" seccion align-items-center justify-content-center "} ${themeModeS}`}
        >
          <Col
            xs={12}
            md={12}
            lg={7}
            className="text-center order-md-1 order-2 pt-3"
          >
            <h3 className="emmaTitulo">{CurDet.tittleCurso}</h3>
            <p className="pt-2">{CurDet.descripCurso}</p>
            <Row>
              <Col xs={12} className="text-md-start">
                <h3>     ¿Qué aprenderás?</h3>
              </Col>
              <Col xs={12} className="pt-4">
                {CurDet.habCur.map((habCur) => (
                  <Habilidades key={habCur} habCur={habCur} />
                ))}
              </Col>
            </Row>

            <Row>
              <div className="btn-item pb-2">
                <LinkRouter
                  to={`/Compra/${idcurso}`}
                  className={`${"blue"} ${themeModeButton}`}
                >
                  Comprar por $ {` ${CurDet.precioCurso}`}
                </LinkRouter>

                <LinkRouter
                  to={`/Curso/Detalle/VidDemo/${idcurso}`}
                  className={`${"blue"} ${themeModeButton}`}
                >
                  {selectModetheme.estado ? (
                    <img className="iconcur" src={imgPlayD} />
                  ) : (
                    <img className="iconcur" src={imgPlayL} />
                  )}
                  Ver Clase muestra
                </LinkRouter>
              </div>
            </Row>
          </Col>
          <Col xs={10} lg={5} className="order-md-2 order-1">
            <img className="imgdetail" src={CurDet.imageCurso} />
          </Col>
        </Row>
        <Row className={`${themeModeP} ${"justify-content-center"}`}>
          <Col xs={12} md={10} lg={10} className="pt-3">
            <h2 className="text-center emmaTitulo text-uppercase">Temario</h2>
            {CurDet.temCur.map((temCur, indice) => (
              <Temario
                key={indice}
                tittle={temCur.titTem}
                tems={temCur.temsCur}
                indice={indice}
              />
            ))}
          </Col>
        </Row>
        <Row className={`${themeModeS} ${"   justify-content-center"}`}>
          <Col xs={12} md={10} className="pt-3">
            <h2 className="text-center emmaTitulo ">- Detalles del curso -</h2>
          </Col>

          <Row className="justify-content-center align-intems-center pt-3">
            <Col xs={"auto"}>
              <h5>En la compra del curso incluye:</h5>
              <ul>
                <li>
                  <h6>Materiales para el curso</h6>
                  <ul>
                    <li>
                      <p>
                        Tarjeta electrónica Emma.Home.01 de diseño propio con
                        componentes y módulos electrónicos necesarios para
                        desarrollar los temas del curso.
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <h6>Programas para el curso.</h6>
                </li>
                <li>
                  <h6>Videotutoriales para instalar programas.</h6>
                </li>
                <li>
                  <h6>Archivos para el curso.</h6>
                  <ul>
                    <li>
                      <p>
                        Esquemático de la tarjeta y documentación de las
                        conexiones con los módulos electrónicos.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </Col>
          </Row>
          <Row className="justify-content-center text-center pt-2 bold pb-4">
            <Col xs={6} sm={4}>
              {selectModetheme.estado ? (
                <img className="iconcur" src={imgCalendL} />
              ) : (
                <img className="iconcur" src={imgCalendD} />
              )}
              {CurDet.fechacur}
            </Col>

            <Col xs={6} sm={4}>
              Nivel: {CurDet.nivel}
            </Col>

            <Col xs={6} sm={4}>
              {selectModetheme.estado ? (
                <img className="iconcur" src={imgTimeD} />
              ) : (
                <img className="iconcur" src={imgTimeL} />
              )}

              {CurDet.duracCur}
            </Col>
          </Row>
        </Row>
        <Row className={`${themeModeP} ${"pt-4 pb-4 justify-content-center"}`}>
          <Col xs={12}>
            <h2 className="text-center emmaTitulo ">- Ponente -</h2>
          </Col>
          <Row className="pt-4 justify-content-center align-items-center">
            <Col xs={12} md={"auto"} className="text-center">
              <img className="img-person" src={CurDet.imgInstruc} />
            </Col>

            <Col xs={12} md={5} className="text-center pt-2">
              <h2>{CurDet.nomInstruCur}</h2>
              <p className="pt-1 justify">{CurDet.instrucDes1}</p>
              <p className="justify">{CurDet.instrucDes2}</p>
              <h5>{CurDet.instrucDes3}</h5>
            </Col>
          </Row>
        </Row>
        <InfoContact />
        <Footer />
      </Container>
    </>
  );
}

function Habilidades({ habCur }) {
  return (
    <>
      <Row>
        <Col xs={1}>
          <img className="iconcur" src={imgCheck1} />
        </Col>
        <Col xs={11} className="text-start">
          <h6>{habCur}</h6>
        </Col>
      </Row>
    </>
  );
}

function Temario({ tittle, tems, indice }) {
  let indexTit = indice + 1;
  return (
    <>
    <Col xs={"auto"}>
  
      <Col xs={12}>
        <h2>
          {indexTit} .- {tittle}
        </h2>
      </Col>
      <Col xs={11} className="text-start">
        <h6>
          {tems.map((temas) => (
            <ul>
              <Temas key={temas} temas={temas} />
            </ul>
          ))}
        </h6>
      </Col>
      </Col>
    </>
  );
}
function Temas({ temas }) {
  return (
    <>
      <li>
        <h6>{temas}</h6>
      </li>
    </>
  );
}
