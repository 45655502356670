import React, { useContext } from "react";
import { NavbarSeccion } from "../navBar/NavBar";
import { Col, Container, Row } from "react-bootstrap";
import { ModeContex } from "../Context/ContextMode";
import Footer from "../Footer/Footer";
import { Contacto } from "../Contacto/Contacto";
import imgfil from "../Cursos/img/components.jpg";
import imgiot from "../Cursos/img/iot.jpg";

export function RoutePageMoreEmma() {
  const { themeModeS, selectModetheme, themeModeButton, themeModeP } =
    useContext(ModeContex);
  return (
    <>
      <NavbarSeccion />
      <Container fluid>
        <Row
          md={1}
          className={`${themeModeP} ${"justify-content-center justify-content-lg-start seccion"}`}
        >
              <Col xs={10} lg={5} className="ps-5">
                <h1 className="">Emma-IoT</h1>
                <p className="justify">
                  Emma es un ecosistema de software y hardware creado para
                  satisfacer necesidades cotidianas por medio de internet de las
                  cosas. Por medio de la tarjeta electrónica se realizan las
                  conexiones físicas hacia los dispositivos a controlar o
                  monitorear y por medio del software multiplataforma de
                  desarrollo propio se realiza el monitoreo y/o control de los
                  componentes y/o actuadores físicos.
                </p>
              </Col>
              <img className="imgMore" src={imgiot}/>
        </Row>
        <Row
          md={2}
          className={`${themeModeS} ${" justify-content-center justify-content-lg-end  pt-3 pb-3 align-items-center"}`}
        >
            <img className="imgMore" src={imgfil} />

          <feColorMatrix>
            <h3 className="text-center">Filosofia de Emma</h3>
            <p className="justify">
              Lograr que cualquier persona pueda implementar el ecosistema Emma
              por un método intuitivo, llegando al control inteligente de la
              vivienda (confort, seguridad y ahorro energético).
            </p>
          </feColorMatrix>

        </Row>
        <Row
          md={2}
          className={`${themeModeP} ${" justify-content-center  justify-content-lg-start align-items-center pt-3 pb-3"}`}
        >
          <Col>
            <h3 className="text-center">Inicios de Emma</h3>
            <p className="justify">
              Emma se inicia por la necesidad de tener una tarjeta electrónica
              que ayude a monitorear parámetros físicos y manipular actuadores
              electrónicos. Comenzó con una fabricación sencilla que fue
              escalando paulatinamente con la finalidad de implementar más
              funcionalidades sin dejar de un lado la practicidad de la tarjeta,
              con el paso del tiempo también se desarrolló el propio software
              que agiliza la comunicación de la tarjeta electrónica con los
              componentes físicos.
            </p>
          </Col>
        </Row>
      <Contacto />
      <Footer />
      </Container>

    </>
  );
}
