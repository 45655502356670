import React, { Component, useContext } from "react";
import { ModeContex } from "../Context/ContextMode";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { Correo, Whatsapp } from "../redes/redes";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useState } from "react";

export function InfoContact() {
  const { themeModeS } = useContext(ModeContex);

  return (
    <>
      <Row
        id="Contactanos"
        className={`${"seccion align-items-center justify-content-center "} ${themeModeS}`}
      >
        <Col xs={12} className="text-center">
          <h1 className="emmaTitulo text-uppercase">Contáctanos</h1>
          <p>
            Si requieres más Información no dudes en mandarnos mensaje directo
            por:
          </p>
        </Col>
        <Col xs={12} sm={6} lg={3} className="text-center">
          <Correo />

          <h3>Correo</h3>
          <p>hola@emma-iot.com</p>
        </Col>
        <Col xs={12} sm={6} lg={3} className="text-center">
          <Whatsapp />
          <h3>Whatsapp</h3>
          <p>+52 5517049353 </p>
        </Col>
      </Row>
    </>
  );
}
export function Contacto() {
  const { themeModeS } = useContext(ModeContex);

  return (
    <>
      <InfoContact/>
      <Row
        className={`${"secciones align-items-center justify-content-md-center pb-4"} ${themeModeS}`}
      >
        <Col xs={12} md={6}>
          <FormContacto />
        </Col>
      </Row>
    </>
  );
}
function FormContacto() {
  const { themeModeButton } = useContext(ModeContex);
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  return (
    <>
      <Row>
        <h1 className="text-center text-uppercase">escríbenos</h1>
      </Row>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Form.Group as={Col} md="12" controlId="validationCustom01">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Escribe tu nombre"
            />
            <Form.Control.Feedback>Excelente!</Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} md="6" controlId="validationCustom02">
            <Form.Label>Correo</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Ejemplo@email.com"
            />
            <Form.Control.Feedback>Excelente!</Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} md="6" controlId="validationCustom02">
            <Form.Label>Teléfono</Form.Label>
            <Form.Control required type="text" placeholder="+52 123456789" />
            <Form.Control.Feedback>Excelente!</Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} md="12" controlId="validationCustom03">
            <Form.Label>Mensaje</Form.Label>
            <Form.Control required as="textarea" rows={3} />

            <Form.Control.Feedback>Excelente!</Form.Control.Feedback>
          </Form.Group>
        </Row>

        <Button className={`${"blue"} ${themeModeButton}`} type="submit">
          Enviar Mensaje
        </Button>
       
      </Form>
    </>
  );
}
