import React from 'react'
import { NavbarSeccion } from '../navBar/NavBar'
import { Col, Row } from 'react-bootstrap'
 
export function RoutePageRecur() {
  return (
    <>
    <NavbarSeccion/>
    <Row>
      <Col className='seccion'>
      <div>Recursos</div>

      </Col>
    </Row>

    </>
  )
}

