import React, { createContext, useState } from "react";
import imgcurso1 from "./img/curso1.jpg";
import imgcurso2 from "./img/curso2.jpg";
import imgcurso3 from "./img/curso3.jpg";
import imgcurso4 from "./img/curso4.jpg";
import imgInstruc1 from "./img/instructor1.png";
import imgInstruc2 from "./img/instructor2.jpg";

{
  //   ------------ aqui empieza el contexto de los cursos ------------
}

const CursContex = createContext();
const CurItemProvider = ({ children }) => {
  const card = [
    {
      idCurso: "OnBasic01",
      tittleCurso: "Introduccion al mundo IoT",
      imageCurso: imgcurso1,
      descripCurso:
        "En el siguiente curso aprenderas las bases para interactuar con el mundo del internet de las cosas (IoT), con la finalidad de poder aplicarlo en un campo real",
      modalidadCurso: "online",
      precioCurso: 2100,
      nivel: "Básico",
      fechacur: "11/Feb/23",
      totHor: "12 hrs",
      duracCur: " 6 clases de 2 hrs.",
      nomInstruCur: "Emmanuel S.",
      imgInstruc: imgInstruc1,
      instrucDes1:
        "Entusiasta y apasionado de la tecnología, electrónica, programación y robótica, 21 años trabajando con dispositivos eléctricos y  electrónicos.",
        instrucDes2:
        "Experto en diseño de PCB y sistemas embebidos,  profesor dedicado en la enseñanza tecnológica.  ",
        

      instrucDes3: "Fundador de Emma-IoT",
      habCur: [
        "Voluptate duis culpa ullamco labore elit adipisicing cupidatat pariatur officia  ",
        "est labore. Aliquip aute dolore aliqua amet amet et laborum. Excepteur proident labore .",
        "consectetur dolorevoluptate qui qui aliqua cillum. Pariatur labore eiusmod ",
        "nulla in. Ut ut ipsum fugiat ut quis voluptate labore exercitation amet deserunt est",
        "Aliqua proident elit  pariatur esse ea id ipsum nisi. Ex nisi ut officia qui laboris",
      ],
      temCur: [
        {
          titTem: "Primeros pasos",
          temsCur: [
            "¿Qué es Arduino?",
            "Arduino en el mundo de la Domótica",
            "Características de las diferentes placas de Arduino",
            "Presentación de la placa EMMA.01",
            "Instalación del IDE y librerías necesarias",
          ],
        },
        {
          titTem: "Introducción a la programación en el IDE de Arduino ",
          temsCur: [
            "Adquisicion de datos:  Funciones analogRead() y digitalRead() ",
            "Sensando el ambiente: Movimiento y presencia Temperatura Humedad relativa  Gas butano    Recibir señales infrarrojas",
            "Interactuando con el ambiente: Alarma sonora Encender y apagar aparatos remotamente Tira de LEDs Enviar señales infrarrojas",
          ],
        },

        {
          titTem: "Implementación de IoT",
          temsCur: [
            "Intro a la nodeMCU:  Leyendo sensores",
            "Estableciendo conexión a internet: Intro a Firebase",
            "Fusionando todo",
          ],
        },
      ],
      vidDemo:"https://www.youtube.com/watch?v=VKazmNa8haI&list=PLHpHd46NVQ5xsdHwKzhMfFr0G6oY8R72s"
    },

    {
      idCurso: "onBasic02",
      tittleCurso: "Interconectarse a la Red mediante IoT",
      imageCurso: imgcurso2,
      descripCurso:
        "En el siguiente curso aprenderas las bases para interactuar con el mundo del internet de las cosas (IoT), con la finalidad de poder aplicarlo en un campo real printing and ",
      modalidadCurso: "off-line",
      precioCurso: 2000,
      nivel: "Avanzado",
      fechacur: " 01/Ene/23",
      totHor: "12 hrs",
      duracCur: " 6 clases de 2 hrs.",
      nomInstruCur: "Emmanuel S.",
      imgInstruc: imgInstruc1,
      instrucDes3: "Fundador de Emma-IoT",

      instrucDes1:
      "Emmanuel Sánchez es entusiasta y apasionado de la tecnología, electrónica, programación y robótica, 21 años trabajando con dispositivos eléctricos y  electrónicos.",
      instrucDes2:
      "Experto en diseño de PCB y sistemas embebidos,  profesor dedicado en la enseñanza tecnológica.  ",
      
      habCur: [
        "Voluptate duis culpa ullamco labore elit adipisicing cupidatat pariatur officia  ",
        "est labore.   dolore  amet amet et laborum. Excepteur proident labore .",
        "consectetur   duis voluptate qui qui aliqua cillum. Pariatur labore eiusmod ",
        "nulla in. Ut ut ipsum anim fugiat ut quis voluptate labore exercitation amet deserunt est",
      ],
      temCur: [
        {
          titTem: "Primeros pasos",
          temsCur: [
            "¿Qué es Arduino?",
            "Arduino en el mundo de la Domótica",
            "Características de las diferentes placas de Arduino",
            "Presentación de la placa EMMA.01",
            "Instalación del IDE y librerías necesarias",
          ],
        },
        {
          titTem: "Introducción a la programación en el IDE de Arduino ",
          temsCur: [
            "Adquisicion de datos:  Funciones analogRead() y digitalRead() ",
            "Sensando el ambiente: Movimiento y presencia Temperatura Humedad relativa  Gas butano    Recibir señales infrarrojas",
            "Interactuando con el ambiente: Alarma sonora Encender y apagar aparatos remotamente Tira de LEDs Enviar señales infrarrojas",
          ],
        },

        {
          titTem: "Implementación de IoT",
          temsCur: [
            "Intro a la nodeMCU:  Leyendo sensores",
            "Estableciendo conexión a internet: Intro a Firebase",
            "Fusionando todo",
          ],
        },
      ],
    },

    {
      idCurso: "OnlineInter01",
      tittleCurso: "Aplicando la domotica en un ambiente laboral",
      imageCurso: imgcurso3,
      descripCurso:
        "En el siguiente curso aprenderas las bases para interactuar con el mundo del internet de las cosas (IoT), con la finalidad de poder aplicarlo en un campo real and typesetting industry. Lorem Ipsum ",
      modalidadCurso: "online",
      precioCurso: 2350,
      nivel: "Intermedio",
      fechacur: "01/Mar/23",
      totHor: "12 hrs",
      duracCur: " 2 clases de 6 hrs.",
      nomInstruCur: "Emmanuel S.",
      imgInstruc: imgInstruc1,
      instrucDes3: "Fundador de Emma-IoT",
      instrucDes1:
      "Emmanuel Sánchez es entusiasta y apasionado de la tecnología, electrónica, programación y robótica, 21 años trabajando con dispositivos eléctricos y  electrónicos.",
      instrucDes2:
      "Experto en diseño de PCB y sistemas embebidos,  profesor dedicado en la enseñanza tecnológica.  ",
      
      habCur: [
        "Ad adipisicing laborum id ipsum. Occaecat et dolore quis ea in. Incididunt anim",
        "est labore. Aliquip aulore aliqua amet amet et laborum. Excepteur proident labore .",
        "consectetur dolore commodo duis voluptate qui qui aliqua cillum. Pariatur labore eiusmod ",
        "nulla in. Utum anim fugiat ut quis voluptate labore exercitation amet deserunt est",
        "Aliqua atur esse ea id ipsum nisi. Ex nisi ut officia qui laboris adipisicing",
      ],
      temCur: [
        {
          titTem: "Primeros pasos",
          temsCur: [
            "¿Qué es Arduino?",
            "Arduino en el mundo de la Domótica",
            "Características de las diferentes placas de Arduino",
            "Presentación de la placa EMMA.01",
            "Instalación del IDE y librerías necesarias",
          ],
        },
        {
          titTem: "Introducción a la programación en el IDE de Arduino ",
          temsCur: [
            "Adquisicion de datos:  Funciones analogRead() y digitalRead() ",
            "Sensando el ambiente: Movimiento y presencia Temperatura Humedad relativa  Gas butano    Recibir señales infrarrojas",
            "Interactuando con el ambiente: Alarma sonora Encender y apagar aparatos remotamente Tira de LEDs Enviar señales infrarrojas",
          ],
        },

        {
          titTem: "Implementación de IoT",
          temsCur: [
            "Intro a la nodeMCU:  Leyendo sensores",
            "Estableciendo conexión a internet: Intro a Firebase",
            "Fusionando todo",
          ],
        },
      ],
    },
    {
      idCurso: "PreslInter02",
      tittleCurso: "Aplicando la domotica en un ambiente laboral Pt.2 ",
      imageCurso: imgcurso4,
      descripCurso:
        "En el siguiente curso aprenderas las bases para interactuar con el mundo del internet de las cosas (IoT), con la finalidad de poder aplicarlo en un campo real and typesetting industry. Lorem Ipsum ",
      modalidadCurso: "presencial",
      precioCurso: 2350,
      nivel: "Intermedio",
      fechacur: "01/Mar/23",
      totHor: "12 hrs",
      duracCur: " 2 días de 6 hrs",
      nomInstruCur: "Emmanuel S.",
      imgInstruc: imgInstruc1,
      instrucDes1:
        "Entusiasta y apasionado de la tecnología, electrónica, programación y robótica, 21 años trabajando con dispositivos eléctricos y  electrónicos.",
        instrucDes2:
        "Experto en diseño de PCB y sistemas embebidos,  profesor dedicado en la enseñanza tecnológica.  ",
        

      instrucDes3: "Fundador de Emma-IoT",
      habCur: [
        " 1 Ad adipisicing laborum id ipsum. Occaecat et dolore quis ea in. Incididunt anim",
        "est labore.lore aliqua amet amet et laborum. Excepteur proident labore .",
        "consecteturmmodo duis voluptate qui qui aliqua cillum. Pariatur labore eiusmod ",
        "nulla in. Ut ut ipsnim fugiat ut quis voluptate labore exercitation amet deserunt est",
      ],
      temCur: [
        {
          titTem: "Primeros pasos",
          temsCur: [
            "¿Qué es Arduino?",
            "Arduino en el mundo de la Domótica",
            "Características de las diferentes placas de Arduino",
            "Presentación de la placa EMMA.01",
            "Instalación del IDE y librerías necesarias",
          ],
        },
        {
          titTem: "Introducción a la programación en el IDE de Arduino ",
          temsCur: [
            "Adquisicion de datos:  Funciones analogRead() y digitalRead() ",
            "Sensando el ambiente: Movimiento y presencia Temperatura Humedad relativa  Gas butano    Recibir señales infrarrojas",
            "Interactuando con el ambiente: Alarma sonora Encender y apagar aparatos remotamente Tira de LEDs Enviar señales infrarrojas",
          ],
        },

        {
          titTem: "Implementación de IoT",
          temsCur: [
            "Intro a la nodeMCU:  Leyendo sensores",
            "Estableciendo conexión a internet: Intro a Firebase",
            "Fusionando todo",
          ],
        },
      ],
    },
  ];

  let CurOnline = [];
  let CurOffline = [];
  let CurPresen = [];
  for (var i = 0; i < card.length; i++) {
    if (card[i].modalidadCurso === "online") {
      CurOnline.push(card[i]);
    }
    if (card[i].modalidadCurso === "off-line") {
      CurOffline.push(card[i]);
    }
    if (card[i].modalidadCurso === "presencial") {
      CurPresen.push(card[i]);
    }
  }

  const data = { card, CurOnline, CurOffline, CurPresen };

  return <CursContex.Provider value={data}>{children}</CursContex.Provider>;
};
export { CurItemProvider };

export { CursContex };
