import React, { useContext } from "react";
import { CursContex } from "../Cursos/CursContext";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { NavbarComprar } from "../navBar/NavBar";
import { ModeContex } from "../Context/ContextMode";
import Accordion from "react-bootstrap/Accordion";
import Footer from "../Footer/Footer";
import efectivoimg from "./img/efectivo.png";
import Transferenciaimg from "./img/transferencia.png";
import paypalimg from "./img/paypal.png";
import imgtarget from "./img/emmtarj.png";

export function CompraCur() {
  let { idcurso } = useParams();

  let { card } = useContext(CursContex);

  let CurCompr = card.find((card) => card.idCurso === idcurso);

  const { themeModeP, themeModeS, themeModeButton } = useContext(ModeContex);

  return (
    <>
      <NavbarComprar />
      <Row className={`${"seccion justify-content-center pb-4"} ${themeModeP}`}>
        <Col className="text-center">
          <h2>Detalles de la compra</h2>
        </Col>
      </Row>
      <Row
        className={`${" align-items-center justify-content-center pb-4"} ${themeModeP}`}
      >
        <Col xs={12} lg={3} className="text-center imgCompcur">
          <img src={CurCompr.imageCurso} />
        </Col>

        <Col xs={12} lg={5} className="text-center">
          <div className="">
            <h4>Curso: {CurCompr.tittleCurso}</h4>
            <p>Instructor: {CurCompr.nomInstruCur}</p>
            <p>Fecha de inicio: {CurCompr.fechacur}</p>
           
            <p>
             Total de horas: {CurCompr.totHor}
            </p>
          </div>
        </Col>
        <Col xs={12} lg={3} className="text-center">
          <Col xs={12} lg={"auto"} className="text-center">
            Resumen
          </Col>
          <Col xs={12} lg={"auto"} className="text-center bold">
            <p> ${CurCompr.precioCurso} MXM + IVA</p>
          </Col>
          <button className={`${"blue"} ${themeModeButton}`}>
            {" "}
            <img className="iconcur2" src={efectivoimg} /> Efectivo
          </button>
          <button className={`${"blue"} ${themeModeButton}`}>
            <img className="iconcur2" src={Transferenciaimg} />
            Transferencia
          </button>
          <button className={`${"blue"} ${themeModeButton}`}>
            <img className="iconcur2" src={paypalimg} />
            PayPal
          </button>
        </Col>
        <Col className="text-center text-md-start"></Col>
      </Row>
      <Row
        className={`${themeModeS} ${" justify-content-center  align-intems-center pt-3"}`}
      >
        <Col xs={12} className="text-center">
          <h3>En la compra del curso incluye:</h3>
        </Col>
        </Row>

      <Row
        className={`${themeModeS} ${" justify-content-center align-items-center pb-5"}`}
      >
       
        <Col xs={12} md={"auto"} lg={3} className="text-center text-lg-start">
          <img className="imgtarget" src={imgtarget} />
        </Col>
        <Col xs={"auto"} lg={8} className="pt-2">
          <ul>
            <li>
              <h6>Materiales para el curso</h6>
              <ul>
                <li>
                  <p>
                    Tarjeta electrónica Emma.Home.01 con módulos electrónicos  para el curso.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <h6>Programas para el curso.</h6>
            </li>
            <li>
              <h6>Videotutoriales para instalar programas.</h6>
            </li>
            <li>
              <h6>Archivos para el curso.</h6>
              <ul>
                <li>
                  <p>
                    Esquemático de la tarjeta y documentación de las conexiones
                    con los módulos electrónicos.
                  </p>
                </li>
              </ul>
            </li>
          </ul>
        </Col>
      </Row>

      <Footer />
    </>
  );
}

function Btncomprar() {
  const { themeModeS, themeModeButton } = useContext(ModeContex);

  return (
    <>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>PayPal</Accordion.Header>
          <Accordion.Body>
            {/*     <ButtonPayPal/>
             */}{" "}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Pago en efectivo</Accordion.Header>
          <Accordion.Body>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Transferencia</Accordion.Header>
          <Accordion.Body>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
}
