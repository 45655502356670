import React, { useContext } from "react";
import { NavbarSeccion } from "../navBar/NavBar";
import { Col, Container, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { CursContex } from "./CursContext";
import { ModeContex } from "../Context/ContextMode";
import { CardCurso, EjemCurso } from "./Curso";
import Footer from "../Footer/Footer";

export function RoutePageCur() {
  let { card, CurOnline, CurOffline, CurPresen } = useContext(CursContex);
  const { themeModeS, themeModeButton } = useContext(ModeContex);

  return (
    <>
      <NavbarSeccion />
      <Container fluid>

      <Row
        className={`${"seccion align-items-center justify-content-center "} ${themeModeS}`}
      >
        <Row className="justify-content-center">
          <Col xs={12} className="text-center text-uppercase pt-2 pb-2">
            <h3 className="emmaTitulo">Cursos On-line</h3>
          </Col>
          {CurOnline.map((CurMap) => (
            <CardCurso
              key={CurMap.idCurso}
              idCur={CurMap.idCurso}
              tittle={CurMap.tittleCurso}
              imageSource={CurMap.imageCurso}
              descripCur={CurMap.descripCurso}
              modCur={CurMap.modalidadCurso}
              niv={CurMap.nivel}
              precio={CurMap.precioCurso}
              iniCur={CurMap.fechacur}
              totHor={CurMap.totHor}

            />
          ))}
        </Row>
        <Row className="justify-content-center">
          <Col xs={12} className="text-center text-uppercase pt-2 pb-2">
            <h3 className="emmaTitulo">Cursos Off-line</h3>
          </Col>
          {CurOffline.map((CurMap) => (
            <CardCurso
              key={CurMap.idCurso}
              idCur={CurMap.idCurso}
              tittle={CurMap.tittleCurso}
              imageSource={CurMap.imageCurso}
              descripCur={CurMap.descripCurso}
              modCur={CurMap.modalidadCurso}
              niv={CurMap.nivel}
              precio={CurMap.precioCurso}
              iniCur={CurMap.fechacur}
              totHor={CurMap.totHor}
            />
          ))}
        </Row>{" "}
        <Row className="justify-content-center">
          <Col xs={12} className="text-center text-uppercase pt-2 pb-2">
            <h3 className="emmaTitulo">Cursos Presencial</h3>
          </Col>
          {CurPresen.map((CurMap) => (
            <CardCurso
              key={CurMap.idCurso}
              idCur={CurMap.idCurso}
              tittle={CurMap.tittleCurso}
              imageSource={CurMap.imageCurso}
              descripCur={CurMap.descripCurso}
              modCur={CurMap.modalidadCurso}
              niv={CurMap.nivel}
              precio={CurMap.precioCurso}
              iniCur={CurMap.fechacur}
              totHor={CurMap.totHor}

            />
          ))}
        </Row>
      </Row>
      </Container>
       <Container fluid>
          <Footer/>
        </Container>     
    </>
  );
}

{
  /* function CardCurso2() {
  let { card, CurOnline, CurOffline, CurPresen } = useContext(CursContex);
  const { themeModeP, themeModeButton } = useContext(ModeContex);
  return (
    <>
      <Row xs={1} md={2} className="g-4 justify-content-center">
        <Col className="Text-center">
          <Card
            border="secondary"
            className={`${" text-center"} ${themeModeP}`}
          >
            <Card.Img variant="top" src="holder.js/100px160" />
            <Card.Body>
              <Card.Title>Card title</Card.Title>
              <Card.Text>
                This is a longer card with supporting text below as a natural
                lead-in to additional content. This content is a little bit
                longer.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card border="info">
            <Card.Img variant="top" src="holder.js/100px160" />
            <Card.Body>
              <Card.Title>Card info</Card.Title>
              <Card.Text>
                This is a longer card with supporting text below as a natural
                lead-in to additional content. This content is a little bit
                longer.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col className="Text-center">
          <Card border="primary">
            <Card.Img variant="top" src="holder.js/100px160" />
            <Card.Body>
              <Card.Title>Card title</Card.Title>
              <Card.Text>
                This is a longer card with supporting text below as a natural
                lead-in to additional content. This content is a little bit
                longer.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <br />

      <Card border="secondary" style={{ width: "18rem" }}>
        <Card.Header>Header</Card.Header>
        <Card.Body>
          <Card.Title>Secondary Card Title</Card.Title>
          <Card.Text>
            Some quick example text to build on the card title and make up the
            bulk of the card's content.
          </Card.Text>
        </Card.Body>
      </Card>
      <br />

      <Card border="success" style={{ width: "18rem" }}>
        <Card.Header>Header</Card.Header>
        <Card.Body>
          <Card.Title>Success Card Title</Card.Title>
          <Card.Text>
            Some quick example text to build on the card title and make up the
            bulk of the card's content.
          </Card.Text>
        </Card.Body>
      </Card>
      <br />

      <Card border="danger" style={{ width: "18rem" }}>
        <Card.Header>Header</Card.Header>
        <Card.Body>
          <Card.Title>Danger Card Title</Card.Title>
          <Card.Text>
            Some quick example text to build on the card title and make up the
            bulk of the card's content.
          </Card.Text>
        </Card.Body>
      </Card>
      <br />

      <Card border="warning" style={{ width: "18rem" }}>
        <Card.Header>Header</Card.Header>
        <Card.Body>
          <Card.Title>Warning Card Title</Card.Title>
          <Card.Text>
            Some quick example text to build on the card title and make up the
            bulk of the card's content.
          </Card.Text>
        </Card.Body>
      </Card>
      <br />

      <Card border="info" style={{ width: "18rem" }}>
        <Card.Header>Header</Card.Header>
        <Card.Body>
          <Card.Title>Info Card Title</Card.Title>
          <Card.Text>
            Some quick example text to build on the card title and make up the
            bulk of the card's content.
          </Card.Text>
        </Card.Body>
      </Card>
      <br />

      <Card border="dark" style={{ width: "18rem" }}>
        <Card.Header>Header</Card.Header>
        <Card.Body>
          <Card.Title>Dark Card Title</Card.Title>
          <Card.Text>
            Some quick example text to build on the card title and make up the
            bulk of the card's content.
          </Card.Text>
        </Card.Body>
      </Card>
      <br />

      <Card border="light" style={{ width: "18rem" }}>
        <Card.Header>Header</Card.Header>
        <Card.Body>
          <Card.Title>Light Card Title</Card.Title>
          <Card.Text>
            Some quick example text to build on the card title and make up the
            bulk of the card's content.
          </Card.Text>
        </Card.Body>
      </Card>
      <br />
    </>
  );
}
export function CardCursoI({
  tittle,
  idCur,
  imageSource,
  descripCur,
  modCur,
  iniCur,
}) {
  return (
    <>
      <Row>
        <Col>{tittle}</Col>
        <Col>{modCur}</Col>
      </Row>
    </>
  );
} */
}
