import React, { useContext } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import iconfacebook from "./img/png/005-facebook.png";
import iconInstagram from "./img/png/003-instagram.png";
import iconEmail from "./img/png/015-correo-electronico-1.png";
import iconTiktok from "./img/png/001-tik-tok.png";
import iconTwitter from "./img/png/010-gorjeo-3.png";
import iconWhatsApp from "./img/png/013-whatsapp-2.png";
import iconYoutube from "./img/png/006-youtube.png";
import "./classIcon.css";
import { ModeContex } from "../Context/ContextMode";

export function Redes() {
  const { themeModeP } = useContext(ModeContex);
  return (
    <>
      <Row className= {`${"align-items-center justify-content-md-center secciones "} ${themeModeP}`}>
        <Col xs={12} className="text-center">
          <h1 className="text-uppercase">
          Síguenos
          </h1>
          <h3 className="text-uppercase">En nuestras redes sociales:</h3>
        </Col>
      </Row>
      <Row className= {`$"align-items-center justify-content-center secciones " ${themeModeP}`}>
      <Col xs={2} sm={1}>
          <Facebook />
        </Col>

        <Col xs={2} sm={1}>
          <Youtube />
        </Col>

        <Col xs={2} sm={1}>
          <Instagram />
        </Col>

        <Col xs={2} sm={1}>
          <Tiktok />
        </Col>

        <Col xs={2} sm={1}>
          <Twitter />
        </Col>
      </Row>
    </>
  );
}

{
  /*  Aqui estan realizados en componentes los iconos de redes sociales para su reutilizacion*/
}

function Facebook() {
  return (
    <>
      <a href="https://www.facebook.com/EMMA.IoT" className="text-center">
        <img className="iconredes" src={iconfacebook} />
      </a>
    </>
  );
}
function Youtube() {
  return (
    <>
      <a href="https://youtube.com/EmmaIot">
        <img src={iconYoutube} className="iconredes" />
      </a>
    </>
  );
}
function Instagram() {
  return (
    <>
      <a href="https://www.instagram.com/emma_iot/">
        <img src={iconInstagram} className="iconredes" />
      </a>
    </>
  );
}

function Tiktok() {
  return (
    <>
      <a href="https://www.tiktok.com/@emmaiot">
        <img src={iconTiktok} className="iconredes" />
      </a>
    </>
  );
}

function Twitter() {
  return (
    <>
      <a href="https://twitter.com/EmmaIot">
        <img src={iconTwitter} className="iconredes" />
      </a>
    </>
  );
}

export function Correo() {
  return (
    <>
      <Col>
        <a href="mailto:emmaiot02@gmail.com">
          <img src={iconEmail} className="iconcontact" />
        </a>
      </Col>
    </>
  );
}

export function Whatsapp() {
  return (
    <>
      <Col>
        <a  href="https://wa.me/525517049353?text=Hola%20me%20interesa%20tu%20curso,%20deseo%20más%20información">
          <img src={iconWhatsApp} className="iconcontact" />
        </a>
      </Col>
    </>
  );
}
